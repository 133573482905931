import { 
	createSelector
} from '@reduxjs/toolkit';

/**
 * @description selects some data of the store
 * @param store, global store of the platform
 * @returns the logged user
 */
export const getUser = (store: any): Array<any> => store.user.token;


/******************************************************************************/
/******************************** STORE ACTION ********************************/
/******************************************************************************/

/**
 * @description return some information of the store
 * @return the logged user
 */
export const selectUser = createSelector([ getUser ], (value) => value );
