import {
    combineReducers
} from 'redux';
import {
    configureStore
} from '@reduxjs/toolkit';
import userReducer from './reducers/user.reducer';
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';
import { persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'

const rootReducer = combineReducers({
    user: userReducer
});

const persistConfig = {
    key: "root",
    storage,
};
  
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        })
    }
});

export const persistor = persistStore(store);