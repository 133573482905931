
/** 
 * Definition of the initial state of the reducer 
*/
const initialState: any = {
    token: null
};

/** Definition of the current state of the reducer */
const currentState: any = { ...initialState };

/**
 * @description Executes the differents action about the routing
 * @param state The current state of the configuration
 * @param action to perform on the current state
 * @returns the state updated with the action performed
 */
const userReducer = (state = currentState, action: any) => {
	switch (action.type) {
	case 'SET_TOKEN':
		return { ...state, token: action.payload.token };
    case 'REMOVE_USER':
		return { ...state, token: null};
	default:
		return state;
	}
};

export default userReducer;