import {
    useContext
} from 'react';
import {
    useTranslation
} from 'react-i18next';
import {
    Navigate
} from 'react-router-dom';
import {
    NotificationContext
} from '../context/notification-provider';
import {
    selectUser
} from '../store/selectors/user.selector';
import {
    connect
} from 'react-redux';

const ProtectedRoute = (props: any) => {
    /** Retrieve components */
    const { token, children } = props;
    /** Notification utilities */
    const { showNotification } = useContext(NotificationContext);
    /** Translation utilities */
    const { t } = useTranslation();
    
    if (!token) {
        showNotification({ severity: 'warning', title: t('title.ups'), message: 'You\'re not logged into Admin Panel.' });

        return <Navigate to="/admin" replace />;
    }

    return children;
}


const mapStateToProps = (store: any) => ({
    token: selectUser(store)
});

export default connect(mapStateToProps, null)(ProtectedRoute);