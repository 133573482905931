import {
    notification
} from "antd";
import {
    createContext
} from "react";

export const NotificationContext = createContext({ showNotification: (_: NotificationProps) => null });

interface NotificationProps {
    severity: 'info' | 'success' | 'warning' | 'error',
    title?: String,
    message?: String,
    timeout?: Number
}

const NotificationProvider = ({ children }: any) => {
    const [api, contextHolder] = notification.useNotification();

    const showNotification = (information: NotificationProps): void => {
        (api as any)[information.severity]({
            message: information.title,
            description: information.message,
            // time in seconds
            duration: information.timeout ? information.timeout : 2
        });
    }
    

    return (
        <NotificationContext.Provider value={{ showNotification } as any}>
            { contextHolder }
            { children }
        </NotificationContext.Provider>
    )
}

export default NotificationProvider;
