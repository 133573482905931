import React from 'react';
import {
    RouterProvider
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import router from './router/router';
import 'animate.css';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo/client';
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from 'react-i18next';
import {
	Provider
} from 'react-redux';
import en_translation from './translation/en.json';
import ca_translation from './translation/ca.json';
import es_translation from './translation/es.json';
import fr_translation from './translation/fr.json';
import de_translation from './translation/de.json';
import NotificationProvider from './context/notification-provider';
import Loading from './screens/loading';
import { store } from './store/store';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: { translation: en_translation },
            ca: { translation: ca_translation },
            es: { translation: es_translation },
            fr: { translation: fr_translation },
            de: { translation: de_translation },
        },
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
});

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <NotificationProvider>
                        <RouterProvider router={router} fallbackElement={ <Loading /> } />
                    </NotificationProvider>
                </Provider>
            </I18nextProvider>
        </ApolloProvider>
    </React.StrictMode>,
);

reportWebVitals();
